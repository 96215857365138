import axios from 'axios'

import { Options } from './api.service.d'

// axios.defaults.baseURL = 'http://localhost:8000/api/v1'
axios.defaults.baseURL = 'https://api.musify.it/api/v1'
axios.defaults.headers.common.Accept = 'application/json'

class Api {
  static _token: string | undefined = undefined

  static request = async (method: any, url: string, params: any = {}, headers: any = {}, data: any = {}, useToken: boolean) => {
    if (useToken) {
      headers.Authorization = `Bearer ${Api._token}`
    }

    return axios({ method, url, params, headers, data })
  }

  static get = async (url: string, opt: Options, useToken = false) => Api.request('get', url, opt.params, opt.headers, opt.data, useToken)
  static post = async (url: string, opt: Options, useToken = false) => Api.request('post', url, opt.params, opt.headers, opt.data, useToken)
  static delete = async (url: string, opt: Options, useToken = false) => Api.request('delete', url, opt.params, opt.headers, opt.data, useToken)
  static patch = async (url: string, opt: Options, useToken = false) => Api.request('patch', url, opt.params, opt.headers, opt.data, useToken)
  static put = async (url: string, opt: Options, useToken = false) => Api.request('put', url, opt.params, opt.headers, opt.data, useToken)

  // App requests

  static profile = async () => Api.get('/user/', {}, true)

  static login = async (email: string, password: string) => Api.post('/login', { data: { email, password } })

  static getPlaylists = async () => Api.get('/user/playlists', {}, true)

  static createPlaylist = async (formData: any) =>
    Api.post('/playlists/create', { data: formData, headers: { 'Content-Type': 'multipart/form-data' } }, true)

  static updatePlaylist = async (formData: any) =>
    Api.post('/playlists/update', { data: formData, headers: { 'Content-Type': 'multipart/form-data' } }, true)

  static deletePlaylist = async (id: any) => Api.post('/playlists/delete', { data: { playlist_id: id } }, true)

  static searchSongs = async (query: string) => Api.get('/songs/search', { params: { query: query } }, true)

  static resetToken = () => {
    Api._token = ''
  }
  static setToken = (value: any) => {
    Api._token = value
  }
}

export default Api
