import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'

import App from '@/components/app'
import { store } from '@/features'
import history from '@/utils/history'

// see https://www.smashingmagazine.com/2020/08/redux-real-world-application/

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <App />
    </Router>
  </Provider>,
  document.getElementById('root'),
)
