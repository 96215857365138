// import React from 'react'

// import { authSlice } from '@/features/auth/_auth.feature'

// export default () => {
//   return (
//     <h1>
//       <a href="https://github.com/JaeYeopHan/cra-template-unicorn" target="_blank" rel="noopener noreferrer">
//         cra-template-unicorn
//       </a>
//     </h1>
//   )
// }

import React, { FC, lazy, Suspense, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

// import { RootState } from '../rootReducer'
import { RootState, useAppDispatch } from '@/features'
import { setAuthState } from '@/features/auth/auth.slice'
import Api from '@/services/api/api.service'

const LoginScreen = lazy(() => import('@/screens/login/login.screen'))
const MainScreen = lazy(() => import('@/screens/main/main.screen'))
// const Home = lazy(() => import('../features/home/Home'))

const App: FC = () => {
  const token = useSelector((state: RootState) => state.auth.token)

  // checks for saved token on storage
  useEffect(() => {
    Api.setToken(token)
  }, [token])

  const isLoggedIn = useSelector((state: RootState) => state.auth.isAuthenticated)

  return (
    <Router>
      <Switch>
        <Route path="/">
          <Suspense fallback={<p>Loading...</p>}>{isLoggedIn ? <MainScreen /> : <LoginScreen />}</Suspense>
          {/* <Suspense fallback={<p>Loading...</p>}>
            <MainScreen />
          </Suspense> */}
        </Route>
      </Switch>
    </Router>
  )
}

export default App
