import { createSlice, PayloadAction } from '@reduxjs/toolkit'

// see https://www.smashingmagazine.com/2020/08/redux-real-world-application/

interface AuthState {
  token: string | null
  isAuthenticated: boolean
}

const initialState: AuthState = {
  token: null,
  isAuthenticated: false,
}

const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    // saveToken(state, { payload }: PayloadAction) {
    saveToken(state, action: PayloadAction<string>) {
      if (action.payload) {
        state.token = action.payload
      }
    },
    //
    clearToken(state) {
      state.token = null
    },
    //
    setAuthState(state, action: PayloadAction<boolean>) {
      state.isAuthenticated = action.payload
    },
  },
})

export const { saveToken, clearToken, setAuthState } = auth.actions
export default auth.reducer
